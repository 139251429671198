import React from 'react';
import _last from 'lodash/last';
const trainings = {
    mc_4413_1: 'Circle of Parents Facilitator Training',

    mc_4413_2: 'Community Resilience Model',

    mc_4413_3: 'Connections Matter',

    mc_4413_4: 'NFSN Standards of Quality',

    mc_4413_5: 'Powerful Possibilities',

    mc_4413_6: 'Promising Solutions',

    mc_4413_7: 'Protective Factors Framework',

    mc_4413_8: 'Protective Factors Train-the-Trainer',

    mc_4413_9: 'Starting Strong',
};

export default ({ row, isCsv }) => {
    let trainingList = [];

    for (const [key, value] of Object.entries(trainings)) {
        if (row[`variables.${key}`] === 'checked') {
            trainingList.push(value);
        }
    }

    if (row['trainingTitle.tx_4431']) {
        trainingList = [`${row['trainingTitle.tx_4431']}`];
    }

    if (trainingList.length > 0) {
        if (isCsv) {
            const last = _last(trainingList);
            return (
                <>
                    {trainingList.map((item, i) => {
                        if (item === last) {
                            return `${item}`;
                        }
                        return `${item}, `;
                    })}
                </>
            );
        }
        return (
            <ul>
                {trainingList.map((item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </ul>
        );
    }

    return null;
};
