import Modal from 'cccisd-modal';
import React, { useState, useRef } from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import style from './style.css';
import getDeploymentProps from '../../helpers/getDeploymentProps';
import Tooltip from 'cccisd-tooltip';
import PencilIcon from 'cccisd-icons/pencil2';
import GlassIcon from 'cccisd-icons/search';
const EditSurvey = ({ deploymentId, deploymentHash, groupId, isView, loadData, checkProgress, config, spaceRight }) => {
    const [settings, setSettings] = useState(null);
    const modal = useRef();

    // const closeModal = () => {
    //     modal.current.close();
    // };

    const deploymentInfo = {
        hash: deploymentHash,
    };
    return (
        <>
            <Modal
                key={deploymentId}
                ref={modal}
                trigger={
                    <Tooltip title={isView ? 'View Survey' : 'Edit Survey'}>
                        <button
                            key={deploymentId}
                            type="button"
                            className={isView ? 'btn btn-primary' : 'btn btn-success'}
                            style={spaceRight ? { marginRight: '1em' } : {}}
                        >
                            {isView ? <GlassIcon /> : <PencilIcon />}
                        </button>
                    </Tooltip>
                }
                title={isView ? 'View Survey' : 'Edit Survey'}
                size="large"
                beforeShow={async () => {
                    const variables = await getDeploymentProps({ deploymentInfo, groupingUnitId: groupId }, config);

                    if (isView) {
                        variables.flowProps.isPrintMode = true;
                    }

                    setSettings(variables);
                }}
                afterClose={() => {
                    loadData();
                    checkProgress();
                }}
            >
                <div className={style.fixContainer}>
                    {settings && (
                        <DeploymentPlayer
                            {...settings}
                            onComplete={() => {
                                // if (!isView) {
                                //     closeModal();
                                // }
                            }}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
};

export default EditSurvey;
