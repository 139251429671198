import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'cccisd-formik';
import MultiSelect from '../MultiSelect';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import getDeploymentProps from '../../helpers/getDeploymentProps.js';
import { connect } from 'react-redux';
import ArrowLeft from 'cccisd-icons/arrow-left7';
import style from './style.css';
import dashboardConfig from '../../../config.js';

const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;
const Boilerplate = window.cccisd.boilerplate;

const Survey = props => {
    const { deploymentInfo, tableRef, modalRef } = props;
    const isProfDevDoc = deploymentInfo.handle === 'profDevDoc';

    const projectLabel = props.selectOptions?.questProjectList?.find(
        item => item.value === props.questProjectId
    )?.label;

    const currentConfig = dashboardConfig[actingRole]?.questProjectList
        ?.find(item => item.label === projectLabel)
        ?.deployments?.find(item => item.handle === deploymentInfo.handle);

    const [loading, setLoading] = useState(true);
    const [configData, setConfigData] = useState(null);
    const [showForm, setShowForm] = useState(false);

    // const isSiteVisit = deploymentInfo.handle === 'siteVisit';
    // const isBiannualFeedback = deploymentInfo.handle === 'biannualFeedback';

    useEffect(() => {
        const surveyConfig = currentConfig.survey;

        if (isProfDevDoc) {
            setShowForm(true);
        } else {
            getData(props, surveyConfig);
        }
    }, []);

    const getData = async (p, config) => {
        setLoading(true);
        const settings = await getDeploymentProps(p, config);
        setConfigData(settings);
        setLoading(false);
    };

    const onComplete = async () => {
        // CHANGE NEEDED!!!! - get Label from survey question query
        // Dont know if this will be used?
        if (deploymentInfo.handle === 'facilitatorForm') {
            try {
                const settings = {
                    assignmentId: configData.deployment.assignment.assignmentId,
                    description: null,
                    hash: Math.random().toString(36).substring(2, 9),
                    isOpen: false,
                    label: 'TestName3',
                    respondentsFromDcwId: null,
                    roleHandle: 'respondent',
                    opensAt: '',
                    closesAt: '',
                    settings: {
                        options: configData.surveyList,
                        orgId: props.groupingUnitId,
                        orgName: configData.orgName,
                        recaptcha: false,
                        autoLogout: true,
                        isRepeated: false,
                        allowRetake: false,
                        requirePass: false,
                        useEndpoint: false,
                        closedRoster: false,
                        requireLogin: true,
                        closeOnTarget: false,
                        closedMessage: 'This survey is no longer accepting responses.',
                        completionUrl: null,
                        passcodeLabel: 'Your Passcode',
                        autoLogoutTime: 20,
                        repeatInterval: null,
                        completedMessage: 'You have already completed this survey. Thank you!',
                        respondentConfig: 'managed',
                        completionMessage: 'You have completed the survey! Thank you for your response!',
                        otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
                        chainEmailFromPawn: null,
                        chainPhoneFromPawn: null,
                        individualPasscode: false,
                        logoutOnCompletion: false,
                        passcodeBackground: 'solidColor',
                        respondentSelection: 'metricspawn',
                        postCompletionAction: 'message',
                        anonAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
                        knownAutoLogoutMessage:
                            '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
                        passcodeBackgroundColor: '#F1F0F5',
                        branchedCompletionConfig: [],
                        isAssignmentPlanEligible: false,
                        passcodeCompletionButtonText: 'Ok',
                        showReviewButtonOnCompletion: false,
                        metricToRespondentPropertyMappings: [],
                    },
                    timepoint: '',
                    type: 'group',
                };

                const response = await axios.post(Boilerplate.route('api.assignmentDeployment.store'), settings);

                if (response?.data?.status === 'success') {
                    window.location = Boilerplate.url('dashboard');
                } else {
                    notification({ message: response?.data?.errors?.group[0], type: 'danger' });
                }
            } catch (e) {
                console.error(e);
            }
        }

        modalRef.current.close();
        tableRef.current.loadData();
    };

    const onSubmit = () => {
        getData(props, currentConfig.survey);
        setShowForm(false);
    };

    const renderForm = () => {
        return (
            <Formik
                onSubmit={onSubmit}
                render={({ values, setFieldValue }) => {
                    return (
                        <Form>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Field
                                    name="multiSelect"
                                    component={MultiSelect}
                                    label="Select TA Org(s):"
                                    options={props.selectOptions.guList}
                                />
                            </div>
                            <div className={style.buttonRow}>
                                <button type="button" className="btn btn-default">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    };

    return (
        <>
            {showForm ? (
                <>{renderForm()}</>
            ) : (
                <>
                    {isProfDevDoc && (
                        <button
                            className="btn btn-warning"
                            onClick={() => {
                                setShowForm(true);
                            }}
                            type="button"
                        >
                            <ArrowLeft spaceRight /> Return to TA Org Selection
                        </button>
                    )}
                    <Loader loading={loading} removeChildren>
                        {configData && <DeploymentPlayer {...configData} onComplete={onComplete} />}
                    </Loader>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    orgList: state.app.orgList.orgList,
});

export default connect(mapStateToProps, {})(Survey);
