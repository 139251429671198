import { client } from 'cccisd-apollo';
const Fortress = window.cccisd.fortress;

export default async (props, config) => {
    const { deploymentInfo, groupingUnitId, orgList } = props;
    const { flowProps, gql, pawnType } = config;

    const gqlVariableBank = {
        groupingUnitId,
        deploymentHash: deploymentInfo.hash,
        orgList: orgList?.map(item => item.value),
        orgMember: props.orgMember?.value,
        deploymentId: props.deploymentId,
        assignmentId: deploymentInfo.assignmentId,
        // pawnRegex: new RegExp(`"mc_5636_id": "4_${props.orgMember?.value}"`).toString(),
        metricsPawn: props.metricsPawn?.pawnId,
    };

    const gqlVars = {};

    for (const [key, value] of Object.entries(gql.variables)) {
        const match = gqlVariableBank[value];
        if (match) {
            gqlVars[key] = match;
        }
    }

    const response = await client.query({
        query: gql.query,
        fetchPolicy: 'network-only',
        variables: gqlVars,
    });

    const roles = response?.data?.roles;
    const groupingUnit = response?.data?.groups?.groupingUnit;

    const userLists = {
        staffMemberList: roles?.uberadminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        groupedOrgAdminList: groupingUnit?.childRoles?.guAdminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        otherOrgAdminList: roles?.guAdminList?.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.user.fullName,
            };
        }),
        orgList: orgList?.map(item => {
            return {
                pawnId: item.pawnId,
                pawnHash: item.pawnHash,
                name: item.label,
            };
        }),
        adminFacilitatorList: roles?.guAdminList
            ?.map(item => {
                return {
                    pawnId: item.pawn.pawnId,
                    pawnHash: item.pawn.pawnHash,
                    name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                };
            })
            ?.concat(
                roles?.instructorList?.map(item => {
                    return {
                        pawnId: item.pawn.pawnId,
                        pawnHash: item.pawn.pawnHash,
                        name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                    };
                })
            ),
    };

    const flowVars = {
        lastUpdatedBy: `${Fortress.user.acting.user.full_name}, ${Fortress.user.acting.user.username}`,
        organizationName: groupingUnit?.group?.label,
        organizationId: groupingUnit?.group?.groupId,
        staffMembers: roles?.uberadminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        groupedOrgAdmins: groupingUnit?.childRoles?.guAdminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        otherOrgAdmins: roles?.guAdminList?.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        }),
        adminFacilitators: roles?.guAdminList
            ?.map(item => {
                return {
                    id: item.pawn.pawnId,
                    name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                };
            })
            ?.concat(
                roles?.instructorList?.map(item => {
                    return {
                        id: item.pawn.pawnId,
                        name: `${item.user.fullName} - ${item.parentGroup.groupingUnit.group.label}`,
                    };
                })
            ),
        // goals: response?.data?.flows?.assignmentProgressList
        //     ?.filter(item => item?.orgMember?.mc_5636_id === `4_${props.orgMember?.value}`)
        //     ?.map((item, i) => {
        //         return { id: i, name: item?.goal?.mc_4458 };
        //     }),
        orgMemberName: props.orgMember?.label,
        orgMemberId: props.orgMember?.value,
    };

    const pawns = {
        metricspawn: {
            pawnId: groupingUnit?.childRoles?.metricspawn?.pawn?.pawnId,
            pawnHash: groupingUnit?.childRoles?.metricspawn?.pawn?.pawnHash,
        },
        actingPawn: {
            pawnId: Fortress.user.acting.id,
            pawnHash: Fortress.user.acting.random_hash,
        },
        orgMember: {
            pawnId: props.orgMember?.value,
            pawnHash: props.orgMember?.hash,
        },
    };

    const deploymentProps = {
        flowProps: {
            hidePlayButton: true,
            userLists: {},
            variables: {},
        },
    };

    if (flowProps?.variables?.length > 0) {
        flowProps.variables.forEach(variable => {
            const match = flowVars[variable];
            if (match) {
                deploymentProps.flowProps.variables[variable] = match;
            }
        });
    }
    // assemble userListså
    if (flowProps?.userLists?.length > 0) {
        flowProps.userLists.forEach(list => {
            const match = userLists[list];

            if (match) {
                deploymentProps.flowProps.userLists[list] = match;
            }
        });
    }

    deploymentProps.deploymentId = response?.data?.flows?.deployment?.deploymentId;

    deploymentProps.pawnId = pawns[pawnType].pawnId;

    deploymentProps.pawnHash = pawns[pawnType].pawnHash;

    return deploymentProps;
};
